$yellow: #DEB887;
$border-radius: .5rem;
$border: 1px solid white;
$questrial: "Questrial";
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1440px;

html, body {
    background: black;
    padding: 0;
    margin: 0;
    font-family: 'WorkSans';
    font-size: 1rem;
    font-weight: normal;
    font-style: normal;
    overflow-x: hidden;
    width: 100vw;
    scroll-behavior: smooth;
}

h1, h2, h3, h4, h5, h6 {
    font-family: $questrial;
    font-weight: normal;
}

a {
    text-decoration: none;
    color:white;
}

// Navbar

.menu {
    position: fixed;
    top: 0;
    right: -100%;
    width: 100%;
    height: 100vh;
    background: transparentize(black, .2);
    font-family: $questrial;
    color: white;
    z-index: 500;
    transition: .3s;

    &.open {right: 0;}

    display: flex;
    justify-content: center;
    align-items: center;

    .navbar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        height: 70%;
    }

    .navbar-brand {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;

        &__img {
            width: 50px;
        }

        &__title {
            font-size: 1.5rem;
            margin-left: 1.5rem;
        }
    }

    .navbar-menu {
        height: 70%;
        list-style: none;
        padding: 0;
        margin: 0;

        &__item {
            margin: 1.5rem 0;
            text-align: center;
        }


        &__link {
            font-size: 1.75rem;
            padding: .5rem;
        }
        .btn-primary {
            border: none;
            border-radius: 0;
        }
    }
}

// Menu opener

.menu-opener {
    position: fixed;
    top: 1rem;
    right: 1rem;
    width: 40px;
    height: 30px;
    z-index: 1000;
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;

    &::before {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        content: '';

        background-color: $yellow;
        border-radius: 5rem;
        width: 55px;
        height: 55px;
    }

    .line {
        position: absolute;
        background: white;
        width: 100%;
        height: 3px;
        content: '';
        transition: .3s;
        transform-origin: center;
    }

    .line:nth-child(1) {
        transform: rotate(0deg);
        top: 0;
        width: 70%;
        animation: line1-close .4s ease forwards;
    }

    .line:nth-child(3) {
        transform: rotate(0deg);
        bottom: 0;
        width: 70%;
        animation: line3-close .4s ease forwards;
    }

    &.cross {
        .line:nth-child(1) {
            animation: line1 .4s ease forwards;
        }
        .line:nth-child(2) {
            width: 0;
        }
        .line:nth-child(3) {
            animation: line3 .4s ease forwards;
        }
    }
}

// Header

header {
    height: 100vh;
    background: url('../img/images/header_background.webp');
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    position: relative;

    @media only screen and (min-width: 768px) {
        background-size: cover;
        background-repeat: no-repeat;
    }

    &::before {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: radial-gradient(transparentize(black, .4), black);
        content: '';
    }

    .content {
        z-index: 2;
    }

    .title {
        color:white;
        h1 {font-size: 2.5rem; margin: 0; margin-bottom: 1rem;}
        .subtitle {margin: 0; font-size: 1.5rem;}
        .header-contact {
            display: flex;
            flex-direction: column;
            gap: .5rem;
            margin: 2rem 0 0;
            a {font-size: 1rem; display: flex; align-items: center; justify-content: center; gap: .5rem;}
        }

        @media only screen and (min-width: 768px) {
            h1 {font-size: 3.5rem;}
            .subtitle {font-size: 2rem;}
            .header-contact {flex-direction: row; gap: 2rem; justify-content: center;}
        }
    }

    .continue {
        position: absolute;
        bottom: 3%;
        left: 50%;
        transform: translateX(-50%);
        text-transform: uppercase;
        font-family: $questrial;
        p {margin: 0; z-index: 1;}
        svg {
            z-index: -1;
            animation: wheel 2s linear infinite;

            @keyframes wheel {
                0%, 100%, 25.1% {transform: translateY(0px) rotateX(0deg); width: 40px;}
                6.25% {transform: translateY(20px) rotateX(90deg); width: 30px;}
                12.5% {transform: translateY(0px) rotateX(180deg); width: 20px;}
                18.75% {transform: translateY(-20px) rotateX(270deg); width: 30px;}
                25% {transform: translateY(0px) rotateX(360deg); width: 40px;}
            }
        }
    }

}

// Main

.section {
    position: relative;
    min-height: 100vh;
    color: white;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    z-index: 2;

    .background-text {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        text-align: center;
        color: white;
        opacity: .2;
        font-family: 'Questrial';
        font-size: 80vw;

        display: flex;
        align-items: center;
        justify-content: center;

        z-index: -1;

        p {margin: 0;}

        @media only screen and (min-width: 768px) {
            font-size: 35vw;
        }
    }

    &__header {
        font-family: 'Questrial';
        text-transform: uppercase;
        font-size: 4rem;
        text-align: center;
        margin: 1rem;
    }

    &__text {
        font-size: 1.25rem;
        max-width: 85%;
        text-align: center;

        @media only screen and (min-width: 768px) {
            max-width: 700px;
        }
    }
}

.subsite .section.gallery {
    justify-content: flex-start;

    .filter {
        margin-bottom: 1rem;
        max-width: 85%;
        width: 100%;
        select {
            padding: .5rem .5rem;
            margin: 0;
            outline: none;
            color: white;
            font-size: .9rem;
            font-weight: 400;
            cursor: pointer;
            border: none;
            border-radius: .5rem;
            background:#202020;

            option {
                color: white;
                background-color: black;

                &:hover {
                    color: black;
                }
            }
        }
    }

    @media only screen and (min-width: 768px) {
        .filter {
            max-width: 930px;
        }
    }
}

.gallery {
    .images {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        flex-wrap: wrap;
        max-width: 85%;
        gap: .5rem;

        .lg-react-element {
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            align-items: center;
            flex-wrap: wrap;
            gap: .5rem;
        }

        img {
            height: 40vw;
            width: 40vw;
            object-fit: cover;
            border-radius: $border-radius;
            cursor: pointer;
        }
    }

    .btn {
        font-size: 1.25rem;
        margin-top: 1.5rem;
    }

    @media only screen and (min-width: 768px) {
        .images {
            max-width: 930px;
            img {width: 300px; height: 300px;}
        }
    }
}

.lg-sub-html {
    display: none;
}

// Footer

footer {
    color: white;
    background-image: url('../img/images/footer_background.webp');
    background-size: cover;
    background-position: center;
    position: relative;
    z-index: 2;

    &::before {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: radial-gradient(transparentize(black, .4), black);
        content: '';
        z-index: -1;
    }

    .content {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-direction: column;
        max-width: 85vw;
        margin: auto;

        > div {width: 100%; margin-bottom: 2rem;}

        .contacts {
            text-align: center;

            h3 {font-size: 1.5rem;}
            p {
                font-size: 1.1rem; margin: .5rem 0;
                svg {margin-right: 8px;}
                a {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    width: fit-content;
                    margin: 0 auto;
                }
            }
        }

        .map iframe {
            border-radius: $border-radius;
            border: none;
            width: 100%;
            min-height: 200px;
        }
    }

    .dcreative {
        color: white;
        text-align: center;
        margin: auto;
    }
}

// Footer END

// Subsite

.subsite h1 {
    color:white;
    font-size: 3rem;
    margin: 1.5rem 0;
    margin-top: 3rem;
    text-align: center;
}


// Animations


@keyframes line1 {
    0% {top: 0%; transform: translateY(-0%) rotate(0deg); width: 70%;}
    50% {top: 50%; transform: translateY(-50%) rotate(0deg); width: 70%;}
    100% {top: 50%; transform: translateY(-50%) rotate(45deg); width: 100%;}
}

@keyframes line1-close {
    0% {top: 50%; transform: translateY(-50%) rotate(45deg); width: 100%;}
    50% {top: 50%; transform: translateY(-50%) rotate(0deg); width: 70%;}
    100% {top: 0%; transform: translateY(-0%) rotate(0deg); width: 70%;}
}

@keyframes line3 {
    0% {bottom: 0%; transform: translateY(0%) rotate(0deg); width: 70%;}
    50% {bottom: 50%; transform: translateY(50%) rotate(0deg); width: 70%;}
    100% {bottom: 50%; transform: translateY(50%) rotate(-45deg); width: 100%;}
}

@keyframes line3-close {
    0% {bottom: 50%; transform: translateY(50%) rotate(-45deg); width: 100%;}
    50% {bottom: 50%; transform: translateY(50%) rotate(0deg); width: 70%;}
    100% {bottom: 0%; transform: translateY(0%) rotate(0deg); width: 70%;}
}


// Buttons

.btn-primary {
    border-radius: $border-radius;
    border: $border;
    padding: .5rem 1.5rem;
}

.btn-secondary {
    background: $yellow;
    padding: .5rem 1.5rem;
    border-radius: $border-radius;
}


// ADMIN


